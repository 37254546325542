<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 300px;">
          <label>日期</label>
          <range-date @change="handleDateChange" ref="createTime"></range-date>
        </div>

      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
    </a-table>
  </a-card>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import RangeDate from '@/components/datetime/RangeDate'

export default {
  name: "KeywordStatisticList",
  mixins: [ListMixin],
  components: {RangeDate},
  data() {
    return {
      listGetUrl: "keyword/keywordStatistic",
      showLoading: false,
      pagination: {
        defaultPageSize: 20
      }
    }
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let list = [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "关键字",
          align: "center",
          width: 200,
          dataIndex: "keyword",
        },
        {
          title: "次数",
          width: 250,
          align: "center",
          dataIndex: "total",
        },
        {
          title: "最后搜索时间",
          width: 300,
          align: "center",
          dataIndex: "lastDateTime",
        },
        {}
      ];
      return list
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    afterFetch(data) {
      data.records.forEach((v, index) => {
        v.id = index + 1
      })
    },
    handleDateChange(value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    }
  }
}
;
</script>

<style lang="less" scoped>
.textClick {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
